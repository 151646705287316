import React, { useState, useRef } from "react";
import axios from "axios";
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

import { useReactToPrint } from "react-to-print";

import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';
import { Chip } from "primereact/chip";
import { ScrollPanel } from 'primereact/scrollpanel';

import LoadingDialog from "../../views/LoadingView";
import ReceiptExpress from "./ReceiptExpress";

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getCustomerSender() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/customer/get/sender`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getCustomerReceive() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/customer/get/receive`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getExpressPrice(packageData) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/shippop/price/list`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageData),
    }).then(data => data.json())
};

async function callBookingExpress(packageData) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/shippop/booking`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageData),
    }).then(data => data.json())
};

async function GetMember(reftel) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getNameExpress() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/name`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getBoxExpress(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/box/shop/${packageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getLabelHTML(packageDetail) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/express/shippop/label`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageDetail),
    }).then(data => data.json())
};

async function getProvince() {
    return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json');
};

async function getState() {
    return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json');
};

async function getDistrict() {
    return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json');
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ECD400',
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 2,
}));

function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export default function ExpressView() {
    const [isLoading, setisLoading] = useState(false);
    const [position, setPosition] = useState({});
    const [member, setMember] = useState({});
    const [employee, setEmployee] = useState({});
    const [shop, setShop] = useState({});

    const [item_provinceA, setItemProvinceA] = useState([]);
    const [item_stateA, setItemStateA] = useState([]);
    const [item_districtA, setItemDistrictA] = useState([]);
    const [item_provinceB, setItemProvinceB] = useState([]);
    const [item_stateB, setItemStateB] = useState([]);
    const [item_districtB, setItemDistrictB] = useState([]);

    const [nameExpress, setNameExpress] = useState([]);
    const [boxExpress, setBoxExpress] = useState([]);

    const [cussender, setCusSender] = useState([]);
    const [cussenders, setCusSenders] = useState([]);
    const [cusreceive, setCusReceive] = useState([]);
    const [cusreceives, setCusReceives] = useState([]);
    const [dialogSender, setDialogSender] = useState(false);
    const [dialogReceive, setDialogReceive] = useState(false);
    const [dialogBox, setDialogBox] = useState(false);

    // ทศกัณฐ์แฟมมิลี
    const [reftel, setReftel] = useState('');
    const [refteldisable, setReftelDisable] = useState(false);

    // ผู้ส่ง
    const [searchSender, setSearchSender] = useState('');
    const [nameA, setNameA] = useState('');
    const [telA, setTelA] = useState('');
    const [addressA, setAddressA] = useState('');
    const [provinceA, setProvinceA] = useState('');
    const [districtA, setDistrictA] = useState('');
    const [stateA, setStateA] = useState('');
    const [postcodeA, setPostcodeA] = useState('');

    // ผู้รับ
    const [searchReceive, setSearchReceive] = useState('');
    const [nameB, setNameB] = useState('');
    const [telB, setTelB] = useState('');
    const [addressB, setAddressB] = useState('');
    const [provinceB, setProvinceB] = useState('');
    const [districtB, setDistrictB] = useState('');
    const [stateB, setStateB] = useState('');
    const [postcodeB, setPostcodeB] = useState('');

    // ข้อมูลสินค้า
    const [nameBox, setNameBox] = useState('');
    const [name, setName] = useState(''); //ชื่อ
    const [weight, setWeight] = useState(0); //น้ำหนัก
    const [width, setWidth] = useState(0); //กว้าง
    const [length, setLength] = useState(0); //ยาว
    const [height, setHeight] = useState(0); //สูง

    const [cod, setCod] = useState(0);
    const [declared, setDeclared] = useState(0);

    // Order
    const [dialogExpress, setDialogExpress] = useState(false);
    const [express, setExpress] = useState([]);
    const [order_express, setOrderExpress] = useState([]);
    // const [order_exp, setOrderExp] = useState([]);
    // const [order_box, setOrderBox] = useState([]);
    const [dialogDetail, setDialogDetail] = useState(false);
    const [detail, setDetail] = useState('');
    const [amountBox, setAmountBox] = useState(1);
    const [detailBox, setDetailBox] = useState('');

    // สัญญา
    const [promise, setPromise] = useState(false);
    const [dialogContract, setDialogContract] = useState(false);

    // ชำระเงิน
    const [dialogDiscount, setDialogDiscount] = useState(false);
    const [dialogCheckOut, setDialogCheckout] = useState(false);
    const [paymenttype, setPaymentType] = useState('');
    const [moneyreceive, setMoneyReceive] = useState(0);
    const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
    const [change, setChange] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [resp, setResp] = useState({});

    const [dialogReceipt, setDialogReceipt] = useState(false);
    const [label_html, setLabelHTML] = useState("");
    const componentReceipt = useRef();
    const headlerPrintReceipt = useReactToPrint({
        content: () => componentReceipt.current,
    });
    const componentLabel = useRef();
    const headlerPrintLabel = useReactToPrint({
        content: () => componentLabel.current,
    });

    useState(async () => {
        setisLoading(true);
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            setisLoading(false);
            Swal.fire({
                title: "แจ้งเตือน!",
                text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ยืนยัน",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                }
            })
        } else if (response.level === 'employee') {
            const shop = await getShop(response.data.employee_shop_id);
            if (shop.data.shop_status === false) {
                setisLoading(false);
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                setPosition(shop.data.shop_type)
                setEmployee(response.data);
                setShop(shop.data);
                const provinces = await getProvince();
                setItemProvinceA(provinces.data);
                setItemProvinceB(provinces.data);
                const name_express = await getNameExpress();
                setNameExpress(name_express.data);
                const box = await getBoxExpress(response.data.employee_shop_id);
                setBoxExpress(box.data);
                setisLoading(false);
            }
        }
    });

    // ผู้ส่ง
    const handleStateA = async (e) => {
        e.preventDefault();
        const response = await getState();
        const states = response.data.filter(el => el.province_id === e.value.id);
        setProvinceA(e.value);
        setItemStateA(states);
    };

    const handleDistrictA = async (e) => {
        e.preventDefault();
        const response = await getDistrict();
        const districts = response.data.filter(el => el.amphure_id === e.value.id);
        setStateA(e.value);
        setItemDistrictA(districts);
    };
    const handlePostcodeA = (e) => {
        e.preventDefault();
        setDistrictA(e.value);
        setPostcodeA(e.value.zip_code);
    };

    // ผู้รับ
    const handleStateB = async (e) => {
        e.preventDefault();
        const response = await getState();
        const states = response.data.filter(el => el.province_id === e.value.id);
        setProvinceB(e.value);
        setItemStateB(states);
    };
    const handleDistrictB = async (e) => {
        e.preventDefault();
        const response = await getDistrict();
        const districts = response.data.filter(el => el.amphure_id === e.value.id);
        setStateB(e.value);
        setItemDistrictB(districts);
    };
    const handlePostcodeB = (e) => {
        e.preventDefault();
        setDistrictB(e.value);
        setPostcodeB(e.value.zip_code);
    };

    const handleGetCusSender = async (e) => {
        e.preventDefault();
        const response = await getCustomerSender();
        if (response && response.message) {
            // setCusSender(response.message);
            setCusSenders(response.message);
            setDialogSender(true);
        } else {
            // จัดการกรณีไม่พบข้อมูลผู้ส่ง
            console.error("ไม่พบข้อมูลผู้ส่ง");
        }
    };

    const handleGetCusReceive = async (e) => {
        e.preventDefault();
        const response = await getCustomerReceive();
        // setCusReceive(response.message);
        setCusReceives(response.message);
        setDialogReceive(true);
    };

    const AddressTemplate = (data) => {
        return `${data.address} ${data.district} ${data.state} ${data.province} ${data.postcode}`
    };

    const ButtonCustomerA = (data) => {
        return <Button className="button" onClick={(e) => HeadleSelectCustomerA(e, data)}>เลือก</Button>
    };

    const ButtonCustomerB = (data) => {
        return <Button className="button" onClick={(e) => HeadleSelectCustomerB(e, data)}>เลือก</Button>
    };

    const HeadleSelectCustomerA = async (e, value) => {
        const resq_province = await getProvince();
        const resq_state = await getState();
        const resq_districe = await getDistrict();
        const item_provice = resq_province.data.find((el) => el.name_th === value.province);
        const item_state = resq_state.data.filter((el) => el.province_id === item_provice.id);
        const item_states = item_state.find((el) => el.name_th === value.state);
        const item_district = resq_districe.data.filter((el) => el.amphure_id === item_states.id);
        const item_districts = item_district.find((el) => el.name_th === value.district)

        setItemStateA(item_state);
        setItemDistrictA(item_district)

        setNameA(value.name);
        setTelA(value.tel);
        setAddressA(value.address);
        setProvinceA(item_provice);
        setStateA(item_states);
        setDistrictA(item_districts);
        setPostcodeA(value.postcode);
        setDialogSender(false);
        setCusSender([]);
    };

    const HeadleSelectCustomerB = async (e, value) => {
        const resq_province = await getProvince();
        const resq_state = await getState();
        const resq_districe = await getDistrict();
        const item_provice = resq_province.data.find((el) => el.name_th === value.province);
        const item_state = resq_state.data.filter((el) => el.province_id === item_provice.id);
        const item_states = item_state.find((el) => el.name_th === value.state);
        const item_district = resq_districe.data.filter((el) => el.amphure_id === item_states.id);
        const item_districts = item_district.find((el) => el.name_th === value.district);

        setItemStateB(item_state);
        setItemDistrictB(item_district)

        setNameB(value.name);
        setAddressB(value.address);
        setDistrictB(item_districts);
        setStateB(item_states);
        setProvinceB(item_provice);
        setPostcodeB(value.postcode);
        setTelB(value.tel);
        setDialogReceive(false);
        setCusReceive([]);
    };

    const setBoxStatus = (e) => {
        // console.log(e)
        setNameBox(e);
        setHeight(e.height);
        setLength(e.length);
        setWidth(e.width);
    };

    const handleSearchSender = (e) => {
        if (e.key === 'Enter') {
            setisLoading(true);
            if (searchSender !== '') {
                const sender = cussenders.filter(
                    (el) => el.name.search(searchSender) !== -1 ||
                        el.tel.search(searchSender) !== -1
                );
                setCusSender(sender);
            } else {
                setCusSender(cussenders);
            }
            setSearchSender('');
            setisLoading(false);
        }
    };

    const handleSearchReceive = (e) => {
        if (e.key === 'Enter') {
            setisLoading(true);
            if (searchReceive !== '') {
                const receive = cusreceives.filter(
                    (el) => el.name.search(searchReceive) !== -1 ||
                        el.tel.search(searchReceive) !== -1
                );
                setCusReceive(receive);
            } else {
                setCusReceive(cusreceives);
            }
            setSearchReceive('');
            setisLoading(false);
        }
    };

    // เช็คราคา
    const headleGetPrice = async (e) => {
        e.preventDefault();
        // Ensure districtA and districtB are not undefined
        if (nameA === '' ||
            addressA === '' ||
            districtA === '' ||
            stateA === '' ||
            provinceA === '' ||
            postcodeA === ''
        ) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกข้อมูลผู้ส่งให้ครบถ้วน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
            });
            return false;
        }
        if (nameB === '' ||
            addressB === '' ||
            districtB === '' ||
            stateB === '' ||
            provinceB === '' ||
            postcodeB === ''
        ) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกข้อมูลผู้รับให้ครบถ้วน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
            });
            return false;
        }
        if (name === '' ||
            weight === 0 ||
            width === 0 ||
            length === 0 ||
            height === 0
        ) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกข้อมูลสินค้าให้ครบถ้วน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
            });
            return false;
        }
        if (!promise) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณาอ่านและยอมรับเงื่อนไขและข้อตกลง',
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
            });
            return false;
        }
        if (districtA && districtB) {
            const from = {
                name: `${employee.employee_firstname} ${employee.employee_lastname}`,
                address: shop.shop_address,
                district: shop.shop_subdistrict,
                state: shop.shop_district,
                province: shop.shop_province,
                postcode: shop.shop_postcode,
                tel: employee.employee_phone,
            };
            const origin = {
                name: nameA,
                address: addressA,
                district: districtA.name_th,
                state: stateA.name_th,
                province: provinceA.name_th,
                postcode: `${postcodeA}`,
                tel: telA
            };
            const to = {
                name: nameB,
                address: addressB,
                district: districtB.name_th,
                state: stateB.name_th,
                province: provinceB.name_th,
                postcode: `${postcodeB}`,
                tel: telB
            };
            const parcel = {
                name: name.name,
                weight: weight,
                width: width,
                length: length,
                height: height,
            };
            const data = {
                from: from,
                to: to,
                origin: origin,
                parcel: parcel,
                cod_amount: parseInt(cod),
                declared_value: parseInt(declared),
                shop_number: shop._id,
            };
            setisLoading(true);
            const price = await getExpressPrice(data);
            if (price.status === true) {
                setisLoading(false);
                setExpress(price.new);
                setDialogExpress(true);
            }
        } else {
            // Handle case when districtA or districtB is undefined
            console.error("districtA or districtB is undefined");
        }
    };

    const headleSelectExpress = (value) => async e => {
        const data = {
            origin: {
                name: nameA,
                address: addressA,
                district: districtA.name_th,
                state: stateA.name_th,
                province: provinceA.name_th,
                postcode: `${postcodeA}`,
                tel: telA
            },
            from: {
                name: `${employee.employee_firstname} ${employee.employee_lastname}`,
                address: shop.shop_address,
                district: shop.shop_subdistrict,
                state: shop.shop_district,
                province: shop.shop_province,
                postcode: shop.shop_postcode,
                tel: employee.employee_phone,
            },
            to: {
                name: nameB,
                address: addressB,
                district: districtB.name_th,
                state: stateB.name_th,
                province: provinceB.name_th,
                postcode: `${postcodeB}`,
                tel: telB
            },
            parcel: {
                name: name.name,
                weight: weight, // ข้อมูลน้ำหนักจากตัวแปร weight
                width: width,
                length: length,
                height: height
            },
            cod_amount: value.cod_amount,
            declared_value: value.declared_value,
            insuranceFee: value.insuranceFee,
            courier_code: value.courier_code,
            insurance_code: "DHPY",
            price_remote_area: value.price_remote_area,
            cost_tg: value.cost_tg,
            cost: value.cost,
            price: value.price,
            price_cod: value.price_cod,
            price_cod_vat: value.price_cod_vat,
            total: value.total,
            profit: value.profit,
            total_platform: value.total_platform,
            type: 'parcel',
            amount: 1,
        };
        // setOrderExp([...order_exp, data]);
        setOrderExpress([...order_express, data]);
        setDialogExpress(false);
        CleartData();
    };

    const headleSelectBox = async (e, value) => {
        setDetailBox(value);
        setDialogBox(true);
    };

    const headleConfirmBox = (e) => {
        setDialogBox(false);
        const total = detailBox.price * amountBox;
        const data = {
            name: detailBox.name,
            cost: detailBox.cost,
            price: detailBox.price,
            type: 'product',
            width: detailBox.width,
            length: detailBox.length,
            height: detailBox.height,
            total: total,
            amount: amountBox,
        };
        setOrderExpress([...order_express, data]);
        // setOrderBox([...order_box, data]);
    };

    const headleResetBox = (e) => {
        setDialogBox(false);
    };

    function CleartData() {
        // ผู้ส่ง
        setNameA("");
        setAddressA("");
        setDistrictA("");
        setStateA("");
        setProvinceA("");
        setPostcodeA("");
        setTelA("");
        // ผู้รับ
        setNameB("");
        setAddressB("");
        setDistrictB("");
        setStateB("");
        setProvinceB("");
        setPostcodeB("");
        setTelB("");
        // สินค้า
        setNameBox('');
        setName('');
        setWidth(0);
        setHeight(0);
        setLength(0);
        setWeight(0);
        setCod(0);
        setDeclared(0);
    };

    // Check out
    const calculateTotal = () => {
        return order_express.reduce((total, item) => total + item.total, 0);
    };

    const headleCheckReftel = async () => {
        if (reftel === "") {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        } else {
            setisLoading(true);
            const response = await GetMember(reftel);
            if (response.status) {
                setisLoading(false);
                Swal.fire({
                    title: "ทศกัณฐ์แฟมมิลี่?",
                    icon: "success",
                    text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setReftelDisable(true);
                    }
                })
            } else {
                setisLoading(false);
                Swal.fire({
                    title: 'ทศกัณฐ์แฟมมิลี่!',
                    text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
        }
    };

    const headlerClearReftel = async () => {
        Swal.fire({
            title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
            icon: "warning",
            text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                setReftelDisable(false);
                setReftel('');
            }
        })
    };

    const heandleCheckOut = async () => {
        const order_product = order_express.filter((el) => el.type === 'parcel');
        const order_box = order_express.filter((el) => el.type === 'product');
        if (order_express.length === 0) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (reftel === '' || refteldisable === false) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกและยืนยัน ทศกัณฐ์แฟมมิลี่',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (order_product.length !== 0) {
            if (promise === false) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'กรุณาอ่านและยอมรับเงื่อนไข',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
        }
        if (shop.shop_wallet < calculateTotal()) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ยอดเงินในระบบไม่เพียงต่อการชำระเงิน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        setDialogCheckout(true);
    };

    const headleDiscount = () => {
        if (calculateTotal() === 0) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ยอดรวมรวมไม่เพียงพอต่อการกรอกส่วนลด',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        } else {
            setDialogDiscount(true);
        }
    };

    const headleConfirmDiscount = () => {
        if (discount >= calculateTotal()) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ส่วนลดมากกว่ายอดที่ต้องชำระ ไม่สามารถทำรายการได้',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            setDialogDiscount(false);
            setDiscount(0);
            return false;
        } else {
            setDialogDiscount(false);
        }
    };

    const onHideDialogDiscount = () => {
        setDiscount(0);
        setDialogDiscount(false);
    };

    const headlerPaymentType = (e, value) => {
        if (value === 'เงินสด') {
            setPaymentType(value);
            setMoneyReceive(0);
            setDisabledMoneyReceive(false);
        } else {
            setPaymentType(value);
            setDisabledMoneyReceive(true);
            setMoneyReceive(calculateTotal() - discount);
        }
    };

    const onHideDialogCheckout = () => {
        setDialogCheckout(false);
    };

    const deleteProduct = (value) => e => {
        const position = order_express.indexOf(value);
        order_express.splice(position, 1);
        setOrderExpress([...order_express]);
    };

    const handleDialogDetail = (value) => e => {
        setDialogDetail(true);
        setDetail(value);
    };

    const headleConfirmCheckout = async () => {
        if (moneyreceive < (calculateTotal() - discount)) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }

        const parcel = order_express.filter((el) => el.type === 'parcel');
        const product = order_express.filter((el) => el.type === 'product');

        const changes = moneyreceive - (calculateTotal() - discount);
        setChange(changes);

        const data = {
            maker_id: employee._id,
            shop_id: shop._id,
            product_detail: parcel,
            box_detail: product,
            shop_type: position,
            paymenttype: paymenttype,
            moneyreceive: moneyreceive,
            change: changes,
            discount: discount,
            platform: reftel,
            employee: employee._id,
        };
        // console.log(data)
        setisLoading(true);
        setDialogCheckout(false);
        const response = await callBookingExpress(data);
        if (response.status === true) {
            const label_data = {
                shop_id: response.data.shop_id,
                purchase_id: response.data.purchase_id,
            };
            const label = await getLabelHTML(label_data);
            if (label.status) {
                let label_replace = label.html;
                label_replace = label_replace.replace('www.shippop.com', "");
                label_replace = label_replace.replace('https://www1.shippop.dev/assets/images/logo.jpg', ""); //test
                label_replace = label_replace.replace('https://www1.shippop.com/assets/images/logo.jpg', ""); //main
                setLabelHTML(label_replace);
            }
            setResp(response.data);
            setisLoading(false);
            setDialogReceipt(true);
            setMoneyReceive(0);
            setDiscount(0);
        } else {
            setisLoading(false);
            setDialogCheckout(true);
        }
    };

    const heandleResetOrder = (e) => {
        window.location.reload();
    };

    const heandleResetPage = (e) => {
        window.location.href = "/";
    };

    const ImageTemplate = (image) => {
        if (image.courier_code === 'DHL') {
            return <img src={require('../../assets/express/DHL.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'ARM') {
            return <img src={require('../../assets/express/ARM.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'FLE') {
            return <img src={require('../../assets/express/FLE.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'FLEF') {
            return <img src={require('../../assets/express/FLE.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'ECP') {
            return <img src={require('../../assets/express/ECP.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'EMST') {
            return <img src={require('../../assets/express/EMST.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'KRYD') {
            return <img src={require('../../assets/express/KRY.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'KRYX') {
            return <img src={require('../../assets/express/KRY.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'BEST') {
            return <img src={require('../../assets/express/BEST.png')} alt={image.courier_code} width="80" />
        } else if (image.courier_code === 'SPX') {
            return <img src={require('../../assets/express/SPX.png')} alt={image.courier_code} width="80" />
        }
    };

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            {/* <Box sx={{ bgcolor: 'goldenrod' }}> */}
                            <Grid container className={"container"}>
                                <div className="title" ><h1>ขนส่งพัสดุ (Express)</h1></div>
                                <Box sx={{ width: '100%' }}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={5}>
                                            <TabView style={{ fontFamily: 'Kanit', paddingTop: '0.5rem' }} className="express-header-tabview">
                                                <TabPanel header="พัสดุ">
                                                    <Grid className="form">
                                                        <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายละเอียดผู้ส่ง</Item>
                                                        <Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Button className="button" onClick={handleGetCusSender}>คลังรายชื่อผู้ส่ง</Button>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="name" value={nameA} onChange={(e) => setNameA(e.target.value)} />
                                                                        <label htmlFor="name">ชื่อ-นามสกุล</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="tel" value={telA} onChange={(e) => setTelA(e.target.value)} />
                                                                        <label htmlFor="tel">เบอร์โทร</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="address" value={addressA} onChange={(e) => setAddressA(e.target.value)} />
                                                                        <label htmlFor="address">ที่อยู่</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={provinceA} id="province" onChange={handleStateA} options={item_provinceA} optionLabel='name_th' placeholder='จังหวัด' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        <label htmlFor="province">จังหวัด</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={stateA} id="state" onChange={handleDistrictA} options={item_stateA} optionLabel='name_th' placeholder='อำเภอ' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        <label htmlFor="state">อำเภอ</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={districtA} id="district" onChange={handlePostcodeA} options={item_districtA} optionLabel='name_th' placeholder='ตำบล' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        <label htmlFor="district">ตำบล</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="zipCode" value={postcodeA} readOnly />
                                                                        <label htmlFor="zipCode">รหัสไปรษณีย์</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                        </Item2>
                                                    </Grid>
                                                    <Grid className="form">
                                                        <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายละเอียดผู้รับ</Item>
                                                        <Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Button className="button" onClick={handleGetCusReceive}>คลังรายชื่อผู้รับ</Button>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="name" value={nameB} onChange={(e) => setNameB(e.target.value)} />
                                                                        <label htmlFor="name">ชื่อ-นามสกุล</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="tel" value={telB} onChange={(e) => setTelB(e.target.value)} />
                                                                        <label htmlFor="tel">เบอร์โทร</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="address" value={addressB} onChange={(e) => setAddressB(e.target.value)} />
                                                                        <label htmlFor="address">ที่อยู่</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={provinceB} id="province" onChange={handleStateB} options={item_provinceB} optionLabel='name_th' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        <label htmlFor="province">จังหวัด</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={stateB} id="state" onChange={handleDistrictB} options={item_stateB} optionLabel='name_th' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        <label htmlFor="state">อำเภอ</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={districtB} id="district" onChange={handlePostcodeB} options={item_districtB} optionLabel='name_th' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        <label htmlFor="district">ตำบล</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputText className="custom-InputText" id="zipCode" value={postcodeB} readOnly />
                                                                        <label htmlFor="zipCode">รหัสไปรษณีย์</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                        </Item2>
                                                    </Grid>
                                                    <Grid className="form">
                                                        <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>น้ำหนัก & ขนาด</Item>
                                                        <Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                            {/* <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'> */}
                                                            {/* <Button className="button" onClick={headleGetBoxExpress}>กล่อง</Button> */}
                                                            {/* </Stack> */}
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={name} id="name" onChange={(e) => setName(e.value)} options={nameExpress}
                                                                            optionLabel='name' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        {/* <InputText value={name} onChange={(e) => setName(e.target.value)} className="custom-InputText" /> */}
                                                                        <label htmlFor="name">ชื่อสินค้า</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Dropdown value={nameBox} id="name" onChange={(e) => setBoxStatus(e.value)} options={boxExpress}
                                                                            optionLabel='name' filter style={{ width: '100%', textAlign: 'left' }} />
                                                                        {/* <InputText value={name} onChange={(e) => setName(e.target.value)} className="custom-InputText" /> */}
                                                                        <label htmlFor="name">กล่องพัสดุ</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputNumber className="custom-InputText" id="width" value={width} onValueChange={(e) => setWidth(e.value)} />
                                                                        <label htmlFor="width">กว้าง (ซม.)</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputNumber className="custom-InputText" id="length" value={length} onValueChange={(e) => setLength(e.value)} />
                                                                        <label htmlFor="length">ยาว (ซม.)</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputNumber className="custom-InputText" id="height" value={height} onValueChange={(e) => setHeight(e.value)} />
                                                                        <label htmlFor="height">สูง (ซม.)</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputNumber className="custom-InputText" id="weight" value={weight} onValueChange={(e) => setWeight(e.value)} />
                                                                        <label htmlFor="weight">น้ำหนัก (กรัม)</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputNumber className="custom-InputText" id="cod" value={cod} onValueChange={(e) => setCod(e.value)} />
                                                                        <label htmlFor="cod">COD</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <InputNumber className="custom-InputText" id="insurance" value={declared} onValueChange={(e) => setDeclared(e.value)} />
                                                                        <label htmlFor="insurance">ประกัน (มูลค่าสินค้า)</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            </Stack>
                                                        </Item2>
                                                    </Grid>
                                                    <Grid className="form">
                                                        <Grid item>
                                                            <FloatLabel>
                                                                <Checkbox onChange={e => setPromise(e.checked)} checked={promise}></Checkbox>
                                                                &nbsp;&nbsp;อ่านและยอมรับ <Link onClick={(e) => setDialogContract(true)}>เงื่อนไขและข้อตกลง</Link>
                                                            </FloatLabel>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid className="form">
                                                        <div>
                                                            <Button className="button" onClick={headleGetPrice}>คำนวนราคา</Button>
                                                        </div>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel header="กล่องพัสดุ">
                                                    <Grid className="form">
                                                        <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
                                                        <Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                            <ScrollPanel className="scroll">
                                                                <Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
                                                                    {boxExpress.slice().map((item, index) => (
                                                                        <Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
                                                                            <Grid className='step2item' onClick={(e) => headleSelectBox(e, item)}>
                                                                                <Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
                                                                                    <img src={'https://cdn-icons-png.flaticon.com/512/679/679821.png'} alt="" style={{ width: '100%' }} />
                                                                                </Item3>
                                                                                <Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
                                                                                    <p>
                                                                                        ชื่อ : {item.name}
                                                                                        <br />
                                                                                        ({item.width} * {item.length} * {item.height})
                                                                                    </p>
                                                                                </Item3>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </ScrollPanel>
                                                        </Item2>
                                                    </Grid>
                                                </TabPanel>
                                            </TabView>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Grid className="form">
                                                <Grid container>
                                                    <Grid sx={{ width: '50%', paddingRight: 1 }}>
                                                        <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
                                                        <Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{calculateTotal() - discount}</Item2>
                                                    </Grid>
                                                    <Grid sx={{ width: '50%', paddingRight: 1 }}>
                                                        <div>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
                                                                <Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                                    <FloatLabel>
                                                                        <InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)} disabled={refteldisable} />
                                                                        <label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    {!refteldisable && (
                                                                        <Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
                                                                    )}
                                                                    {refteldisable && (
                                                                        <Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
                                                                    )}
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
                                                                </Item3>
                                                            </Stack>
                                                            <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <Button className="custom-button-2" label="ส่วนลดในบิล" onClick={headleDiscount} />
                                                                </Item3>
                                                                <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                    <Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
                                                                </Item3>
                                                            </Stack>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid className="form">
                                                <Grid sx={{ width: '100%', paddingRight: 1, paddingTop: 2 }}>
                                                    <DataTable value={order_express} stripedRows paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50]} style={{ fontFamily: 'Kanit' }}>
                                                        <Column header="#" style={{ width: '10%' }} body={(rowData) => (
                                                            <div>
                                                                <Chip label={rowData.type} style={{
                                                                    fontFamily: 'Kanit',
                                                                    paddingLeft: '1rem', paddingRight: '1rem',
                                                                }} />
                                                            </div>
                                                        )}></Column>
                                                        <Column header="ขนส่ง" style={{ width: '10%' }} body={(rowData) => (
                                                            <div>
                                                                {rowData.type === 'parcel' && (
                                                                    <div>
                                                                        {ImageTemplate(rowData)}
                                                                    </div>
                                                                )}
                                                                {rowData.type === 'product' && (
                                                                    <div>
                                                                        <img src={'https://cdn-icons-png.flaticon.com/512/679/679821.png'} alt="" style={{ width: '100%' }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}></Column>
                                                        <Column header="รายละเอียดสินค้า" style={{ width: '35%' }} body={(rowData) => (
                                                            <div>
                                                                {rowData.type === 'parcel' && (
                                                                    <div style={{ fontSize: '12px' }}>
                                                                        <b style={{ fontSize: '18px' }}>{rowData.parcel.name}</b>
                                                                        <br />
                                                                        กว้าง {rowData.parcel.width} ซม. * ยาว {rowData.parcel.length} ซม. * สูง {rowData.parcel.height} ซม.
                                                                        <br />
                                                                        น้ำหนัก {rowData.parcel.weight} กรัม
                                                                        <br />
                                                                        {rowData.to.address} {rowData.to.district} {rowData.to.state} {rowData.to.province}
                                                                    </div>
                                                                )}
                                                                {rowData.type === 'product' && (
                                                                    <div>
                                                                        <b style={{ fontSize: '18px' }}>{rowData.name}</b>
                                                                        <br />
                                                                        กว้าง {rowData.width} ซม. * ยาว {rowData.length} ซม. * สูง {rowData.height} ซม.
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}></Column>
                                                        <Column header="จำนวน" style={{ width: '15%' }} field="amount"></Column>
                                                        <Column header="ยอดสุทธิ" style={{ width: '15%' }} field="total"></Column>
                                                        <Column style={{ width: '20%' }} body={(rowData) => (
                                                            <div>
                                                                {rowData.type === 'พัสดุ' && (
                                                                    <Button icon="pi pi-align-justify" className="p-button-rounded p-button-outlined"
                                                                        onClick={handleDialogDetail(rowData)} />
                                                                )}
                                                                <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
                                                                    style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(rowData)} />
                                                            </div>
                                                        )}></Column>
                                                    </DataTable>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Dialog ข้อมูลผู้ส่ง */}
            <Dialog header="ข้อมูลผู้ส่ง" visible={dialogSender} style={{ width: '60vh', fontFamily: 'Kanit' }} onHide={() => { if (!dialogSender) return; setDialogSender(false); }}
                breakpoints={{ '1680px': '60vw', '641px': '70vw', '430px': '80vw' }}>
                <Grid container spacing={0}>
                    <FloatLabel style={{ width: '50%', marginBottom: '0.5rem' }}>
                        <InputText className="custom-InputText" id="name" value={searchSender} onChange={(e) => setSearchSender(e.target.value)} onKeyUp={handleSearchSender} />
                        <label htmlFor="name">ค้นหาด้วย ชื่อ (แล้วกด Enter)</label>
                    </FloatLabel>
                </Grid>
                {cussender.length === 0 ? (
                    <div>ไม่พบข้อมูลผู้ส่ง</div>
                ) : (
                    <React.Fragment>
                        <Grid container spacing={0}>
                            <DataTable value={cussender} style={{ width: '100%', fontFamily: 'Kanit' }}>
                                <Column field="name" header="ชื่อ"></Column>
                                <Column field="tel" header="เบอโทรศัพท์"></Column>
                                <Column header="ที่อยู่" body={AddressTemplate}></Column>
                                <Column header="ตัวเลือก" body={ButtonCustomerA}></Column>
                            </DataTable>
                        </Grid>
                    </React.Fragment>
                )}
            </Dialog>

            {/* Dialog ข้อมูลผู้รับ */}
            <Dialog header="ข้อมูลผู้รับ" visible={dialogReceive} style={{ width: '60vh', fontFamily: 'Kanit' }} onHide={() => { if (!dialogReceive) return; setDialogReceive(false); }}
                breakpoints={{ '1680px': '60vw', '641px': '70vw', '430px': '80vw' }}>
                <Grid container spacing={0}>
                    <FloatLabel style={{ width: '50%', marginBottom: '0.5rem' }}>
                        <InputText className="custom-InputText" id="name" value={searchReceive} onChange={(e) => setSearchReceive(e.target.value)} onKeyUp={handleSearchReceive} />
                        <label htmlFor="name">ค้นหาด้วย ชื่อ (แล้วกด Enter)</label>
                    </FloatLabel>
                </Grid>
                {cusreceive.length === 0 ? (
                    <div>ไม่พบข้อมูลผู้รับ</div>
                ) : (
                    <React.Fragment>
                        <Grid container spacing={0}>
                            <DataTable value={cusreceive} style={{ width: '100%', fontFamily: 'Kanit' }}>
                                <Column field="name" header="ชื่อ"></Column>
                                <Column field="tel" header="เบอโทรศัพท์"></Column>
                                <Column header="ที่อยู่" body={AddressTemplate}></Column>
                                <Column header="ตัวเลือก" body={ButtonCustomerB}></Column>
                            </DataTable>
                        </Grid>
                    </React.Fragment>
                )}
            </Dialog>

            {/* Dialog กล่องพัสดุส่งสินค้า */}
            <Dialog header="จำนวน" visible={dialogBox} style={{ width: '60vh', fontFamily: 'Kanit' }}
                onHide={() => { if (!dialogBox) return; setDialogBox(false); }}
                breakpoints={{ '1680px': '40vw', '641px': '70vw', '430px': '80vw' }}>
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12}>
                        <label htmlFor="sqm-price" className="font-bold block mb-2">จำนวน : &emsp;</label>
                        <InputNumber inputId="sqm-price" value={amountBox} onValueChange={(e) => setAmountBox(e.value)}
                            mode="decimal" showButtons min={1} />
                        {/* <label htmlFor="sqm-price" className="font-bold block mb-2">&emsp; ชิ้น</label> */}
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="ยืนยัน" style={{ width: '100%' }} onClick={headleConfirmBox} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="ยกเลิก" style={{ width: '100%' }} onClick={headleResetBox} />
                        </div>
                    </Grid>
                </Grid>
            </Dialog>

            {/* Dialog เช็คราคาขนส่ง */}
            <Dialog header="ราคาขนส่ง" visible={dialogExpress} style={{ width: '60vw', fontFamily: 'Kanit' }} onHide={() => { if (!dialogExpress) return; setDialogExpress(false); }}
                breakpoints={{ '1680px': '60vw', '641px': '70vw', '430px': '80vw' }}>
                {express.length === 0 ? (
                    <div>ไม่พบข้อมูลขนส่ง</div>
                ) : (
                    <Grid container spacing={1}>
                        <DataTable value={express} style={{ width: '100%', fontFamily: 'Kanit' }}>
                            <Column header="ขนส่ง" body={ImageTemplate}></Column>
                            <Column field="courier_name" header="ชื่อขนส่ง"></Column>
                            <Column field="estimate_time" header="รายละเอียด"></Column>
                            <Column field="total" header="ราคา (บาท)"></Column>
                            <Column header="ตัวเลือก" body={(rowData) => (
                                <div>
                                    <Button label="เลือก" onClick={headleSelectExpress(rowData)} />
                                </div>
                            )}></Column>
                        </DataTable>
                    </Grid>
                )}
            </Dialog>

            {/* Dialog Detail */}
            <Dialog header="รายละเอียด" visible={dialogDetail} style={{ width: '60vw', fontFamily: 'Kanit' }} onHide={() => { if (!dialogDetail) return; setDialogDetail(false); }}
                breakpoints={{ '1680px': '60vw', '641px': '70vw', '430px': '80vw' }}>
                {detail && (
                    <div style={{ marginBottom: '1rem' }}>
                        <b>ผู้ส่ง : {detail.origin.name}</b><br></br>
                        ที่อยู่ : {detail.origin.address} {detail.origin.state} {detail.origin.district} {detail.origin.province} {detail.origin.postcode}
                        <br></br>
                        เบอร์โทรศัพท์ : {detail.origin.tel}
                        <div></div><br></br>
                        <b>ผู้รับ : {detail.to.name}</b><br></br>
                        ที่อยู่ : {detail.to.address} {detail.to.state} {detail.to.district} {detail.to.province} {detail.to.postcode}
                        <br></br>
                        เบอร์โทรศัพท์ : {detail.to.tel}
                        <div></div><br></br>
                        <b>พัสดุ : {detail.parcel.name}</b><br></br>
                        รายละเอียด : {detail.parcel.width} ซม. / {detail.parcel.height} ซม. / {detail.parcel.length} ซม. / {detail.parcel.weight} กรัม
                        <div></div><br></br>
                        <div className="table-express">
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: '10%' }}>ค่าขนส่ง</th>
                                        <th style={{ width: '10%' }}>COD</th>
                                        <th style={{ width: '10%' }}>ค่าธรรมเนียม COD (3%)</th>
                                        <th style={{ width: '10%' }}>ภาษีค่าธรรมเนียม (7%)</th>
                                        <th style={{ width: '10%' }}>ประกัน (มูลค่าสินค้า)</th>
                                        <th style={{ width: '10%' }}>ค่าธรรมเนียมประกัน</th>
                                        <th style={{ width: '10%' }}>พื้นที่ห่างไกล</th>
                                        <th style={{ width: '10%' }}>พื้นที่ห่างไกล</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '10%' }}>{detail.price}</td>
                                        <td style={{ width: '10%' }}>{detail.cod_amount}</td>
                                        <td style={{ width: '10%' }}>{detail.price_cod}</td>
                                        <td style={{ width: '10%' }}>{detail.price_cod_vat}</td>
                                        <td style={{ width: '10%' }}>{detail.declared_value}</td>
                                        <td style={{ width: '10%' }}>{detail.insuranceFee}</td>
                                        <td style={{ width: '10%' }}>{detail.price_remote_area}</td>
                                        <td style={{ width: '10%' }}>{detail.total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </Dialog>

            {/* Dialog เงื่อนไขและสัญญา */}
            <Dialog header="เงื่อนไขและข้อตกลง" visible={dialogContract} style={{ width: '60vh', fontFamily: 'Kanit' }} onHide={() => { if (!dialogContract) return; setDialogContract(false); }}
                breakpoints={{ '1680px': '60vw', '641px': '70vw', '430px': '80vw' }}>
                <p>
                    1. ระบบนี้เป็นเพียงระบบคีย์พัสดุและเรียกขนส่งนั้นๆ เข้ารับพัสดุเท่านั้น ระยะเวลาในการจัดส่งพัสดุจะช้าหรือเร็ว ขึ้นอยู่กับแบรนด์ขนส่งนั้นๆ ไม่ได้เกี่ยวข้องโดยตรงกับบริษัทฯ
                </p>
                <p>
                    2. ระบบขนส่งดังกล่าวจะมีการเก็บข้อมูลผู้รับและผู้ส่ง ทางร้านค้าจะต้องแจ้งข้อมูลดังกล่าวแก่ลูกค้าเผื่อความยินยอมของลูกค้า
                </p>
                <p>
                    3. การคีย์ข้อมูลขนาดพัสดุ กว้างxยาวxสูง(เซนติเมตร) และน้ำหนักของพัสดุ(กรัม) จะต้องคีย์ตามความจริง <strong> หากคีย์ไม่ตรงกับพัสดุจริงทางบริษัทจะหักเงินในกระเป๋าร้านค้าตามจำนวนส่วนต่างทันที โดยไม่แจ้งให้ทราบล่วงหน้า</strong>
                </p>
                <p>
                    4. หากขนส่งมารับพัสดุหรือร้านค้ายินยอมให้ขนส่งรับพัสดุไปโดยไม่สแกนเข้ารับพัสดุหน้างาน หากพัสดุชิ้นนั้นเกิดการสูญหาย บริษัทจะไม่มีการรับผิดชอบทุกกรณี เนื่องจากทางตัวแทนไม่ปฏิบัติตามกฎและเงื่อนไขในการส่งพัสดุกับทางบริษัทฯ
                </p>
                <p>
                    5. กรณีมีการยกเลิกพัสดุ ทางบริษัทจะคืนเงินเพียงครึ่งหนึ่งของยอดทำรายการ ตามนโยบายของทางบริษัท
                </p>
            </Dialog>

            {/* Dialog Discount */}
            <Dialog header="ส่วนลด" visible={dialogDiscount} style={{ width: '30vw', fontFamily: 'Kanit' }}
                onHide={() => { if (!dialogDiscount) return; setDialogDiscount(false); }}
                breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <FloatLabel className="custom-InputText">
                            <InputNumber className="custom-InputText" id="discount" value={discount} onChange={(e) => setDiscount(e.value)} />
                            <label htmlFor="moneyreceive">ส่วนลดในบิล</label>
                        </FloatLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                            <ButtonGroup>
                                <Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmDiscount} />
                                <Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogDiscount} />
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>

            {/* Dialog Checkout */}
            <Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '30vw', fontFamily: 'Kanit' }}
                onHide={() => { if (!dialogCheckOut) return; setDialogCheckout(false); }}
                breakpoints={{ '1680px': '30vw', '641px': '60vw', '430px': '80vw' }} >
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
                            placeholder="เลือกประเภทการชำระ" className="custom-InputText" />
                    </Grid>
                    <Grid item xs={12}>
                        <FloatLabel className="custom-InputText">
                            <InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
                                disabled={disabledMoneyReceive} />
                            <label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
                        </FloatLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                            <ButtonGroup>
                                <Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
                                <Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>

            {/* Dialog Receipt */}
            <Dialog header="เงินทอน" visible={dialogReceipt} style={{ width: '30vw', fontFamily: 'Kanit' }}
                onHide={() => { if (!dialogReceipt) return; setDialogReceipt(false); }}
                breakpoints={{ '1680px': '30vw', '641px': '60vw', '430px': '80vw' }} closable={false} >
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Card className="border-1 shadow-none border-gray-400" style={{ padding: '0' }}>
                            <h1>
                                {numberFormat(change == null ? '' : change)}
                            </h1>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="ใบเสร็จรับเงิน" style={{ width: '100%' }} onClick={headlerPrintReceipt} />
                            {/* <ReceiptExpress /> */}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="พิมพ์ใบปะหน้า" style={{ width: '100%' }} onClick={headlerPrintLabel} />
                            {/* <ReceiptExpress /> */}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="ทำรายรายต่อ" style={{ width: '100%' }} onClick={heandleResetOrder} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="หน้าหลัก" style={{ width: '100%' }} onClick={heandleResetPage} />
                        </div>
                    </Grid>

                    <div style={{ display: 'none' }}>
                        <div ref={componentLabel}>
                            <div dangerouslySetInnerHTML={{ __html: label_html }} />
                        </div>
                    </div>

                    <div style={{ display: 'none' }}>
                        <div ref={componentReceipt} style={{ fontSize: '16px', textAlign: 'center', padding: '1rem', fontFamily: 'Kanit' }}>
                            <ReceiptExpress data={resp} />
                        </div>
                    </div>
                </Grid>
            </Dialog>


            <LoadingDialog isLoading={isLoading} />
        </div>
    )
};