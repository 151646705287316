import React, { useState, useRef } from "react";
import "./order.css";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useReactToPrint } from "react-to-print";
import DOMPurify from 'dompurify';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import LoadingDialog from "../../views/LoadingView";
import ReceiptExpress from "../express/ReceiptExpress";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getMember(tel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${tel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

async function getOrderExpress(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/express/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function cancelOrderExpress(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/express/shippop/cancel`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

async function getOrderPurchaseId(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/express/purchase/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getLabelHTML(packageDetail) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/express/shippop/label`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageDetail),
	}).then(data => data.json())
};

export default function OrderExpress() {
	const [isLoading, setisLoading] = useState(false);
	const [visibleDetail, setVisibleDetail] = useState(false);
	const [order, setOrderExpress] = useState([]);
	const [me, setMe] = useState("");
	const [emp, setEmp] = useState("");
	const [employee, setEmployee] = useState([]);
	const [detail, setDetail] = useState("");
	const [order_detail, setOrderDetail] = useState([]);
	const [label_html, setLabelHTML] = useState("");
	const [member, setMember] = useState({});

	const componentLabel = useRef();
	const headlerPrintLabel = useReactToPrint({
		content: () => componentLabel.current,
	});
	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	React.useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setMe(response.data);
				const employee_list = await getEmployee(shop.data._id);
				const order_express = await getOrderExpress(shop.data._id);
				setOrderExpress(order_express.data.reverse())
				setEmployee(employee_list.data);
				setEmp(response.data)
			}
		}
	});

	const getNameEmployee = (item) => {
		const emp = employee.find(
			(el) => el._id === item
		);
		if (emp) {
			return <Chip label={emp.employee_firstname} style={{
				fontFamily: 'Kanit',
				paddingLeft: '1rem', paddingRight: '1rem',
				backgroundColor: '#FF0066',
				color: 'white',
			}} />;
		} else {
			return <Chip label='ไม่พบข้อมูลพนักงานดังกล่าว' style={{
				fontFamily: 'Kanit',
				paddingLeft: '1rem', paddingRight: '1rem',
				backgroundColor: '#FF0066',
				color: 'white',
			}} />;
		}
	};

	const getLastStatus = (item) => {
		const status = item[item.length - 1].name;
		return status;
	};

	const getColorStatus = (item) => {
		switch (item) {
			case "รอชำระเงิน":
				return "#e5c33b";
			case "ชำระเงิน":
				return "#339900";
			default:
				return "#f43030";
		}
	};

	const getStatusName = (item) => {
		switch (item) {
			case 'booking':
				return 'บุ๊คกิ้ง';
			case 'confirm':
				return 'ยืนยันการบุ๊ค';
			case 'cancel':
				return 'ยกเลิก';
			case 'shipping':
				return 'ระหว่างจัดส่ง';
			case 'complete':
				return 'จัดส่งสำเร็จ';
			case 'return':
				return 'ตีกลับต้นทาง';
			case 'pending_transfer':
				return 'เตรียมโอนเงินคืน';
			case 'transferred':
				return 'โอนเรียบร้อย';
			case 'cancel_transfer':
				return 'ยกเลิกการโอนคืน'
			default:
				return item;
		}
	};

	const getStatusColor = (item) => {
		switch (item) {
			case 'booking':
				return '#e5c33b';
			case 'confirm':
				return '#24e524';
			case 'cancel':
				return '#f43030';
			case 'shipping':
				return '#ea15ea';
			case 'complete':
				return '#24e524';
			case 'return':
				return '#b7b2b7';
			case 'pending_transfer':
				return '#e5c33b';
			case 'transferred':
				return '#ea15ea';
			case 'cancel_transfer':
				return '#f43030'
			default:
				return '#f43030';
		}
	};

	const getCourierImg = (courier_code) => {
		switch (courier_code) {
			case 'FLE':
				return require('../../assets/express/FLE.png')
			case 'ARM':
				return require('../../assets/express/ARM.png')
			case 'BEST':
				return require('../../assets/express/BEST.png')
			case 'DHL':
				return require('../../assets/express/DHL.png')
			case 'ECP':
				return require('../../assets/express/ECP.png')
			case 'EMST':
				return require('../../assets/express/EMST.png')
			case 'KRYS':
				return require('../../assets/express/KRY.png')
			case 'NJV':
				return require('../../assets/express/NJV.png')
			case 'SCG':
				return require('../../assets/express/SCG.png')
			case 'SCGF':
				return require('../../assets/express/SCGC.png')
			case 'SCGC':
				return require('../../assets/express/SCGC.png')
			case 'SPX':
				return require('../../assets/express/SPX.png')
			case 'TP2':
				return require('../../assets/express/TP2.png')
			default:
				return require('../../assets/express/NONE.png')
		}
	};

	const headleDetail = (value) => async e => {
		setVisibleDetail(true);
		setDetail(value);
		const response = await getOrderPurchaseId(value.purchase_id);
		setOrderDetail(response.data);
		const label_data = {
			shop_id: value.shop_id,
			purchase_id: value.purchase_id,
		};
		const label = await getLabelHTML(label_data);
		console.log(label)
		if (label.status !== false) {
			let label_replace = label.html;
			label_replace = label_replace.replace('www.shippop.com', "");
			// label_replace = label_replace.replace('https://www1.shippop.dev/assets/images/logo.jpg', ""); //test
			label_replace = label_replace.replace('https://www1.shippop.com/assets/images/logo.jpg', ""); //main
			setLabelHTML(label_replace);
		}
		const members = await getMember(value.platform);
		setMember(members.data);
	};

	const heandleCancelExpress = (value) => async e => {
		if (emp.employee_position !== 'manager') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ผู้ใช้งานดังกล่าวไม่สามารถทำรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		} else {
			Swal.fire({
				title: "คุณต้องการยกเลิกรายการ?",
				icon: "question",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "ยืนยัน",
				cancelButtonText: "ยกเลิก",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const data = {
						maker_id: me._id,
						shop_id: me.employee_shop_id,
						purchase_id: value.purchase_id,
					};
					const resp = await cancelOrderExpress(data);
					if (resp.status === true) {
						Swal.fire({
							title: 'สำเร็จ',
							text: `คุณได้ทำการยกเลิกรายการขนส่ง ${value.invoice} สำเร็จ`,
							icon: 'success',
							showConfirmButton: false,
							timer: 3000,
						});
						setOrderExpress([...order])
					}
				}
			})
		}
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>ประวัติการส่งพัสดุ</h1></div>
								<Box sx={{ width: '100%', height: 'auto' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model">
											<DataTable value={order} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]} style={{ fontFamily: 'Kanit' }}>
												<Column header="เลขที่ใบเสร็จ" body={(rowData) => (
													<Chip label={rowData.invoice} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column header="เลขที่ใบสั่งซื้อ" body={(rowData) => (
													<Chip label={rowData.purchase_id} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
														backgroundColor: '#FFD700',
													}} />
												)} ></Column>
												<Column header="ยอดรวม (บาท)" body={(rowData) => (
													<div>
														{numberFormat(rowData.total)}
													</div>
												)}></Column>
												<Column header="การชำระเงิน" field="payment_type" body={(rowData) => (
													<Chip label={rowData.payment_type} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column field="bill_status" header="สถานะ" body={(rowData) => (
													<div>
														<Chip label={getLastStatus(rowData.status)} style={{
															fontFamily: 'Kanit',
															paddingLeft: '1rem', paddingRight: '1rem',
															backgroundColor: getColorStatus(getLastStatus(rowData.status)),
															color: 'white'
														}} />
													</div>
												)}></Column>
												<Column header="เจ้าหน้าที่" field="employee" body={(rowData) => (
													<div>
														{getNameEmployee(rowData.employee)}
													</div>
												)}></Column>
												<Column header="วันที่ทำรายการ" body={(rowData) => (
													<div>
														{datetimeFormat(rowData.timestamp)}
													</div>
												)}></Column>
												<Column header="ตัวเลือก" body={(rowData) => (
													<div>
														<Button outlined icon="pi pi-align-center" severity="help"
															label="รายละเอียด" style={{ fontFamily: 'Kanit' }} onClick={headleDetail(rowData)} />
														{emp.employee_position === 'manager' && (
															<React.Fragment>
																{getLastStatus(rowData.status) !== 'ยกเลิก' && (
																	<Button outlined icon="pi pi-times" severity="danger"
																		label="ยกเลิก" style={{ fontFamily: 'Kanit', marginLeft: '0.5rem' }} onClick={heandleCancelExpress(rowData)} />
																)}
															</React.Fragment>
														)}
													</div>
												)}></Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Sidebar visible={visibleDetail} position="right" onHide={() => setVisibleDetail(false)} className="order-visible">
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ fontFamily: 'Kanit' }}>
						<h2>เลขที่ใบเสร็จ : {detail.invoice}</h2>
						<br />
						<Divider align="center">
							<Chip label="รายการพัสดุ" style={{ paddingLeft: '1rem', paddingRight: '1rem', fontFamily: 'Kanit' }} />
						</Divider>
						<br />
						<DataTable value={order_detail} style={{ fontFamily: 'Kanit' }}>
							<Column header="ขนส่ง" body={(rowData) => (
								<img src={getCourierImg(rowData.courier_code)} alt={rowData.courier_code} width="80" />
							)} ></Column>
							<Column header="เลขพัสดุ" body={(rowData) => (
								<Chip label={rowData.courier_tracking_code} style={{
									paddingLeft: '1rem',
									paddingRight: '1rem',
									fontFamily: 'Kanit',
								}} />
							)} ></Column>
							<Column header="ผู้รับ" body={(rowData) => (
								<p>{rowData.to.name} <br />
									<small><em>{rowData.to.address}, {rowData.to.district}, {rowData.to.state}, {rowData.to.province}, {rowData.to.postcode}</em></small>
								</p>
							)} ></Column>
							<Column header="ราคา" field="total" ></Column>
							<Column header="สถานะ" field="order_status" body={(rowData) => (
								<Chip label={getStatusName(rowData.order_status)}
									style={{
										paddingLeft: '1rem',
										paddingRight: '1rem',
										fontFamily: 'Kanit',
										backgroundColor: getStatusColor(rowData.order_status),
										color: 'white',
									}}
								/>
							)} ></Column>
						</DataTable>
						<Divider align="center">
							<Chip label="รายการกล่องพัสดุ" style={{ paddingLeft: '1rem', paddingRight: '1rem', fontFamily: 'Kanit' }} />
						</Divider>
						<br />
					</Grid>

					<Grid item xs={6} style={{ fontFamily: 'Kanit' }}>
						<Button label="พิมพ์ใบเสร็จ" className="button" icon="pi pi-print" onClick={headlerPrintReceipt} style={{ width: '70%' }} />
					</Grid>

					<Grid item xs={6} style={{ fontFamily: 'Kanit' }}>
						{label_html !== "" && (
							<Button label="พิมพ์ใบปะหน้า" className="button" icon="pi pi-print" onClick={headlerPrintLabel} style={{ width: '70%' }} />
						)}
					</Grid>
				</Grid>

				<div style={{ display: 'none' }}>
					<div ref={componentLabel} style={{ width: '100%' }}>
						<div dangerouslySetInnerHTML={{ __html: label_html }} />
					</div>
				</div>

				<div style={{ display: 'none' }}>
					<div ref={componentReceipt} style={{ fontSize: '16px', textAlign: 'center', padding: '1rem', fontFamily: 'Kanit' }}>
						<ReceiptExpress data={detail} />
					</div>
				</div>
			</Sidebar>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}